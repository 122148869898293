import React from "react";
import { IoLogoLinkedin } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
import { AiOutlineGithub } from "react-icons/ai";
const HeaderSocials = () => {
  return (
    <div className="header_socials">
      <a
        href="https://www.linkedin.com/in/abishek-gurung-ab4aaa20a/"
        target="_blank"
      >
        {" "}
        <IoLogoLinkedin />{" "}
      </a>
      <a href="https://www.facebook.com/abhisek.grg.3/" target="_blank">
        <FaFacebook />
      </a>
      <a href="https://github.com/abi57" target="_blank">
        {" "}
        <AiOutlineGithub />
      </a>
    </div>
  );
};

export default HeaderSocials;
